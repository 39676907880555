<template>
  <div class="flex-row-fluid d-flex flex-column ml-lg-8">
    <div class="card card-custom gutter-b">
      <!--begin::Body-->
      <div class="card-body d-flex align-items-center justify-content-between flex-wrap py-3">
        <!--begin::Info-->
        <div class="d-flex align-items-center mr-2 py-2">
          <!--begin::Title-->
          <h3 class="font-weight-bold mb-0 mr-10">Client Accounts</h3>
          <!--end::Title-->
          <div class="input-icon">
            <input type="text" class="form-control" placeholder="Search..." v-model="search" />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        <!--end::Info-->
        <!--begin::Users-->
        <router-link
          :to="{ name: 'CreateClientCompanyUser', params: { id: $route.params.id } }"
          tag="button"
          class="btn btn-primary font-weight-bolder"
        >
          <span class="svg-icon svg-icon-md">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
            <inline-svg src="/media/svg/icons/Design/Flatten.svg"></inline-svg> </span
          >New account</router-link
        >
      </div>
      <!--end::Body-->
    </div>

    <div v-if="paginatedData.length > 0">
      <transition-group name="list" tag="div" class="row">
        <div class="col-sm-6 col-lg-6 col-xl-3" v-for="user in paginatedData" :key="user.id">
          <!--begin::Card-->
          <client-user-card :user="user" @reset-password="resetPassword" @delete-client="toggleClientStatus" />
          <!--end::Card-->
        </div>
      </transition-group>

      <pagination v-model="pagination" />
    </div>

    <no-data v-else></no-data>
  </div>
</template>

<script>
import { getUsersByCompany, lockClientUser, unlockClientUser, resetClientUserPassword } from "@/api/client.api";

import Pagination from "@/components/general/Pagination";
import ClientUserCard from "./components/ClientUserCard";

import { ref, reactive, onMounted, computed } from "@vue/composition-api";

import Swal from "sweetalert2";

export default {
  name: "listClientCompany",
  components: { Pagination, ClientUserCard },

  setup(_, { root }) {
    const companyId = root.$route.params.id;

    let list = ref([]);
    const search = ref("");

    onMounted(() => refresh());

    const refresh = async () => {
      const response = await getUsersByCompany(companyId);
      list.value = response.data;
    };

    const pagination = reactive({
      currentPage: 1,
      totalRows: computed(() => list.value.length),
      showing: computed(() => paginatedData.value.length),
      itemInPage: 10
    });

    const paginatedData = computed(() => {
      const searchTerm = search.value.toLowerCase();
      let filteredData = list.value.filter(company => {
        for (const key of Object.keys(company)) {
          const val = company[key].toString().toLowerCase();
          if (val.includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });

      let index = (pagination.currentPage - 1) * pagination.itemInPage;
      return filteredData.slice(index, index + pagination.itemInPage);
    });

    const resetPassword = id => {
      Swal.fire({
        title: "Reset Password?",
        text: "This user password will be reset to default, you sure you want to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(async result => {
        if (result.isConfirmed) {
          await resetClientUserPassword(id, companyId);
          Swal.fire("Password Reset!", "This client user password have been reset", "success");
        }
      });
    };

    const toggleClientStatus = (id, status) => {
      if (status == "ACTIVE") {
        Swal.fire({
          title: "Confirm deactivate?",
          text: "You may re-activate this user later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await lockClientUser(id, companyId);
            refresh();
            Swal.fire("Lock!", "This client user have been deactivated", "success");
          }
        });
      } else {
        Swal.fire({
          title: "Confirm Activate?",
          text: "You may de-activate this user later ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!"
        }).then(async result => {
          if (result.isConfirmed) {
            await unlockClientUser(id, companyId);
            refresh();
            Swal.fire("Unlock!", "This client user have been activated", "success");
          }
        });
      }
    };

    return { search, pagination, paginatedData, resetPassword, toggleClientStatus };
  }
};
</script>
